<template>
  <div class="mt-6 mx-3 mx-md-6">
    <v-card class="pa-6 card">
      <v-row>
        <v-col cols="12" class="d-flex align-center">
          <v-avatar size="96" class="secondary">
            <span class="display-1">{{ $shortForm(userInfo.name) }}</span>
          </v-avatar>
          <div class="ml-6">
            <div class="text-h6">{{userInfo.name}}</div>
            <div class="grey--text">
              <v-icon x-small class="primary--text mr-2"
                >mdi-checkbox-blank-circle</v-icon
              >
              <!-- <span class="text-capitalize">{{userInfo.role}}</span>
              <span class="mx-2">|</span> -->
              <span>{{userInfo.email}}</span>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ['userInfo'],
};
</script>

<style>
</style>
